import React from 'react'


export default function Services() {
  return (
    <div class="container">
      <header className="header">
        <h1>Our Services</h1>
      </header>
      <div className='container'>
      At SRHInnovations, we pride ourselves on offering a comprehensive range of services to meet your business needs. Our expert team is dedicated to delivering innovative solutions tailored to your specific requirements. Explore our services below:
      </div>
    <div class="service">
        <h2>Website Development</h2>
        <p>Build stunning and functional websites tailored to your business needs.</p>
    </div>
    <div class="service">
        <h2>App Development</h2>
        <p>Create innovative mobile applications for iOS and Android platforms.</p>
    </div>
    <div class="service">
        <h2>Marketing</h2>
        <p>Boost your brand's visibility and reach with our strategic marketing solutions.</p>
    </div>
    <div class="service">
        <h2>E-Commerce</h2>
        <p>Launch and manage your online store efficiently with our e-commerce solutions.</p>
    </div>
    <div class="service">
        <h2>Web Hosting</h2>
        <p>Reliable and secure web hosting services to keep your website running smoothly.</p>
    </div>
    <div class="service">
        <h2>Testing and Quality Assurance</h2>
        <p>Ensure the quality and reliability of your software with our testing services.</p>
    </div>
    <div class="service">
        <h2>Consultation Services</h2>
        <p>Expert guidance and advice to help you make informed business decisions.</p>
    </div>
</div>
  )
}
