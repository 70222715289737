import React from 'react'

export default function Contact() {
  return (
    <div>
      <div class="container">
      <header className="header">
        <h1>Contact Us</h1>
      </header>
    
    <div class="contact-info">
        <p><strong>Name:</strong> Anmol Rastogi</p>
        <p><strong>Email:</strong> <a href="mailto:srhinnovations1303@gmail.com">srhinnovations1303@gmail.com</a></p>
        <p><strong>Mobile:</strong> <a href="tel:+918957934008">8957934008</a></p>
        <p><strong>Address:</strong> Birhana Road, Kanpur</p>
    </div>
    
    <p class="animate-color">Feel free to reach out to us using the contact details provided above. We look forward to hearing from you!</p>
</div>
    </div>
  )
}
