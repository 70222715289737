import React from 'react'

export default function Pricing() {
  return (
    <div>
      <header className="header">
        <h1>Unlock Pricing Details</h1>
      </header>
        <div class="container">
    <p>At SRHInnovations, we understand that pricing is a crucial factor in your decision-making process. Our commitment to transparency means providing you with accurate and personalized pricing information tailored to your specific needs.</p>
    <p>To receive detailed pricing information for our services or products, please take a moment to fill out the form below. By providing us with some basic information about your requirements, we can offer you a comprehensive pricing proposal that aligns perfectly with your budget and objectives.</p>
    <p>Why wait? Take the first step towards discovering the value and affordability of our solutions. Fill out the form now and gain access to exclusive pricing details designed just for you.</p>
    <p>Let's embark on this journey together. Your success starts with the right pricing strategy. Complete the form and unlock the pricing details you've been waiting for!</p>
</div>
<div class="container">
<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc736h5kA37FwJzMZrGxpD-zqWanYI26jjMvTUIgMyLwOMQPQ/viewform?embedded=true" width="100%" height="1156" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

</div>

    </div>
  )
}
