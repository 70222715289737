import React from 'react'
import './../css/newcss.css'

export default function About() {
  return (
    <div>
      <header className="header">
        <h1>Welcome to SRHInnovations</h1>
      </header>
      <div className="container">
        <p>SRHInnovations, a dynamic venture founded by two seasoned professionals in the field of technology, stands at the forefront of revolutionizing the digital landscape with its cutting-edge web solutions. Established on a shared vision of empowering businesses and individuals with innovative online platforms, the company has swiftly emerged as a beacon of excellence in the realm of web development.</p>
        <p><strong>From its inception, SRHInnovations has been committed to delivering unparalleled quality and exceeding client expectations.</strong> The company offers a comprehensive suite of services encompassing website design, e-commerce development, mobile app development, digital marketing, and more. Whether it's crafting visually stunning interfaces, implementing robust backend systems, or devising strategic marketing campaigns, SRHInnovations prides itself on its ability to translate clients' visions into tangible digital realities.</p>
        <p className="emphasis"><strong>What sets SRHInnovations apart is its emphasis on innovation and adaptability.</strong> The team thrives on staying ahead of the curve, constantly exploring emerging technologies and trends to integrate into their solutions. Whether it's harnessing the power of artificial intelligence for personalized user experiences or leveraging blockchain for enhanced security, SRHInnovations remains at the forefront of technological advancement.</p>
        <p><strong>Moreover, the company's customer-centric approach sets it apart in a crowded marketplace.</strong> Understanding that each client is unique, with distinct goals and requirements, SRHInnovations prioritizes open communication, collaboration, and flexibility throughout the development process, ensuring that every solution is tailored to meet the specific needs of the client.</p>
        <p><strong>As a result of their unwavering dedication to excellence, SRHInnovations has garnered a loyal clientele spanning various industries and geographies.</strong> From startups and SMEs seeking to establish their online presence to established enterprises looking to enhance their digital footprint, clients trust SRHInnovations to deliver solutions that drive results and foster growth.</p>
        <p><strong>Looking ahead, the founders remain committed to pushing the boundaries of innovation and expanding the company's reach.</strong> With a talented team of developers, designers, and marketers, SRHInnovations is poised to continue its trajectory of success, empowering businesses and individuals to thrive in an increasingly digital world.</p>
      </div>
    </div>
  );
}


const headerStyle = {
  backgroundColor: '#00539F',
  color: '#fff',
  padding: '20px',
  textAlign: 'center',
  animation: 'fade-in 5s ease-in-out'
};

const headerText = {
  margin: '0',
  animation: 'slide-in 1s ease-in-out'
};

const containerStyle = {
  maxWidth: '800px',
  margin: '20px auto',
  padding: '20px',
  backgroundColor: '#f4f4f4',
  borderRadius: '8px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  color: '#333',
  lineHeight: '1.6'
};